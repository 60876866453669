@charset "utf-8";


/* アセット関連
--------------------------------------------------*/
@import './assets/setting';
@import './assets/tool/mixin';
@import './assets/reset/normalize';
@import './assets/reset/reset';
@import './assets/module/font';
@import './assets/module/base';





/* ライブラリ関連
--------------------------------------------------*/
@import './libs/owl.carousel';
@import './libs/owl.theme.default';





/* 共通要素
--------------------------------------------------*/
html {
  height: 100%;
}

body {
  // height: 100%;
  // font-family: 'Noto Sans', sans-serif;
  // font-family: 'Lato', sans-serif;
}
body.is_menu_open {
  overflow: hidden;
}

.wrapper {
  padding: 20px 10px 25px 10px;
}
.pc .wrapper {
  padding: 60px 20px 25px 20px;
}

.container1 {
	width: 980px;
	margin: 0 auto;
}

.container2 {
	width: 720px;
	margin: 0 auto;
}

.page_title {
  margin-bottom: 30px;
  font-size: 20px;
  letter-spacing: 0.5em;
  line-height: 1.94;
}

.sec_title {
  margin-bottom: 15px;
  font-size: 18px;
  letter-spacing: 0.15em;
  line-height: 1.75;
}

.back {
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 16px 18px 13px 35px;
  color: #000000;
  background-color: #ffffff;
  background-image: url('../img/button_arrow1.svg');
  background-repeat: no-repeat;
  background-position: 7px center;
  background-size: 21px 20px;
  z-index: 9000;
}
.pc .back {
  background-size: 11px 20px;
}
.ja.sp .back {
  padding: 15px 18px 14px 35px;
}
.ja.sp.android .back {
  padding: 14px 18px 15px 35px;
}
.en.sp .back {
  padding: 14px 18px 15px 35px;
}
.en.sp.android .back {
  padding: 15px 18px 14px 35px;
}
.zn-cn.sp.iphone .back {
  padding: 14px 18px 15px 35px;
}
.zh-tw.sp .back {
  padding: 14px 18px 15px 35px;
}
.ko.sp .back {
  padding: 14px 18px 15px 35px;
}


.build {
  position: fixed;
  width: 125px;
  height: 45px;
  padding: 15px 10px 15px 10px;
  font-size: 16px;
  letter-spacing: 0.83px;
  color: #000000;
  text-align: center;
  background-color: #9b9b9b;
}
.build.active {
  background-color: #ffffff;
  pointer-events: none;
}
.en .build {
  font-size: 14px;
}
.th .build {
  font-size: 11px;
}

.build.on {
  bottom: 55px;
  left: 10px;
}

.build.off {
  bottom: 10px;
  left: 10px;
}


.click {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 6000;
}
.sp .click {
  pointer-events: none;
}
.video_page.page3 .click {
  background-image: url('../img/poster1.jpg');
}
.video_page.page4 .click {
  background-image: url('../img/poster2.jpg');
}

.click .layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.click .tblr_center {
  text-align: center;
}

.click .tblr_center p {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.6;
  color: #ffffff;
}



/* HEADER
--------------------------------------------------*/




/* TOP PAGE
--------------------------------------------------*/
.top_page .bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('../img/top_bg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -100;
}
.pc.top_page .bg {
  background-image: url('../img/top_bg2.jpg');
}

.top_page .logo img {
  display: block;
  width: 100%;
  max-width: 318px;
  height: auto;
  margin: 0 auto 20px auto;
}

.top_page .gnav_container {
  width: 330px;
  margin: 0 auto 25px auto;
  transform: scale(1);
  transform-origin: left top;
}
.top_page .gnav_container.w320 {
  margin: 0 auto -25px auto;
  transform: scale(0.9091);
}

.top_page .gnav_container li {
  margin: 0 0 10px 10px;
}
.top_page .gnav_container li:nth-child(2n+1) {
  margin: 0 0 10px 0;
}

.top_page .gnav_container li a {
  position: relative;
  display: block;
  width: 160px;
  height: 145px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: 160px 145px;
  background-position: 0 0;
}
.top_page .gnav_container li a:hover {
  opacity: 0.85;
}

.top_page .gnav_container li:nth-child(1) a {
  background-image: url('../img/top_gnav1.jpg');
}
.top_page .gnav_container li:nth-child(2) a {
  background-image: url('../img/top_gnav2.jpg');
}
.top_page .gnav_container li:nth-child(3) a {
  background-image: url('../img/top_gnav3.jpg');
}
.top_page .gnav_container li:nth-child(4) a {
  background-image: url('../img/top_gnav4.jpg');
}

.top_page .gnav_container li a:not(.link) span {
  position: absolute;
  width: 97px;
  top: 10px;
  left: 58px;
  font-size: 11px;
  letter-spacing: -0.6px;
  line-height: 1.24;
}
.ja.top_page .gnav_container li a:not(.link) span {
  top: 5px;
  left: 63px;
  font-size: 15px;
  letter-spacing: -0.38px;
}
.th.top_page .gnav_container li a:not(.link) span {
  top: 5px;
}

.top_page .gnav_container li a.link {
  height: 47px;
}
.en.top_page .gnav_container li a.link {
  padding: 4px 0px 0 6px !important;
  // word-break: break-all;
}
.top_page .gnav_container li a.link1 {
  padding: 14px 10px 0 10px;
}
.pc.top_page .gnav_container li a.link1 {
  padding: 15px 10px 0 10px;
}
.th.top_page .gnav_container li a.link1 {
  padding: 7px 10px 8px 10px;
}

.top_page .gnav_container li a.link2 {
  padding: 5px 20px 0 20px;
}
.pc.top_page .gnav_container li a.link2 {
  padding: 7px 20px 0 20px;
}
.th.top_page .gnav_container li a.link2 {
  padding: 7px 5px 0 5px;
  line-height: 0.2;
}

.top_page .gnav_container li a.link span {
  height: 47px;
  font-size: 10px;
  letter-spacing: -0.16px;
  line-height: 1.33;
}
.ja.top_page .gnav_container li a.link span {
  font-size: 13px;
}
.ko.top_page .gnav_container li a.link span {
  letter-spacing: -0.52px;
}
.th.top_page .gnav_container li a.link span {
  font-size: 9px;
}


.top_page .controller {
  position: relative;
  width: 100%;
  max-width: 330px;
  height: 45px;
  margin: 0 auto;
}

.top_page .controller .help {
  position: absolute;
  top: 0;
  left: 0;
}
.top_page .controller .help:hover {
  opacity: 0.65;
}

.top_page .controller .help img {
  width: 45px;
  height: 45px;
}

.top_page .controller .lang {
  position: absolute;
  top: 0;
  right: 0;
}
.top_page .controller .lang:hover {
  opacity: 0.65;
}


.top_page .controller .lang img {
  width: 119px;
  height: 45px;
}


.top_page .lang_container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(162, 143, 122, 1);
  z-index: 8000;
}

.top_page .lang_container .lang_list {
  width: auto;
  text-align: center;
}
.pc.top_page .lang_container .lang_list {
  width: 200px;
}

.top_page .lang_container .lang_list li {
  display: inline-block;
  margin-bottom: 15px;
  padding: 12px 22px;
  font-size: 20px;
  letter-spacing: 2.78px;
  cursor: pointer;
  transition: background-color 0.15s linear 0s;
}
.top_page .lang_container .lang_list li:hover {
  background-color: #ffffff;

}

.top_page .lang_container .lang_list li.current {
  background-color: #ffffff;
}

.top_page .lang_container .close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 34px;
  height: 34px;
  cursor: pointer;
}


.top_page .help_container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 85px 20px 25px 20px;
  background-color: rgba(162, 143, 122, 1);
  z-index: 7000;
}

.top_page .help_container .summary .title {
  margin-bottom: 20px;
  font-size: 17px;
  letter-spacing: 1.21px;
  line-height: 1.5;
}

.top_page .help_container section {
  margin-bottom: 60px;
}
.pc.top_page .help_container section {
  width: 550px;
  margin: 0 auto 60px auto;
}

.top_page .help_container section .title {
  margin-bottom: 15px;
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 1.28px;
}

.top_page .help_container section .description {
  margin-bottom: 30px;
  font-size: 15px;
  letter-spacing: 1.06px;
  line-height: 1.5;
}

.top_page .help_container section .midashi {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.14px;
}

.top_page .help_container .figure1 {
  display: block;
  margin: 15px auto;
  width: 115px;
  height: auto;
}

.top_page .help_container .figure2 {
  display: block;
  margin: 15px auto;
  width: 178px;
  height: auto;
}


.top_page .help_container .close {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 34px;
  height: 34px;
  cursor: pointer;
}





/* DAIBA OLD PAGE
--------------------------------------------------*/
.daiba_page .bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('../img/daiba_bg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -100;
}






/* DAIBA NEW PAGE
--------------------------------------------------*/
.daiba_page .bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -100;
}
.daiba_page.old .bg {
  background-image: url('../img/daiba_bg_old.jpg');
}
.daiba_page.new .bg {
  background-image: url('../img/daiba_bg_new.jpg');
}

.daiba_page .bg .point1,
.daiba_page .bg .point2 {
  position: absolute;
  top: 50%;
  left: 50%;
}

.daiba_page .bg .point1 .circle {
  content: '';
  display: block;
  position: absolute;
  top: -81px;
  left: -81px;
  width: 163px;
  height: 163px;
  border-radius: 50%;
  background-color: rgba(234, 77, 77, 0.5);
  animation: circle_motion 3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
}
.daiba_page .bg .point2 .circle {
  content: '';
  display: block;
  position: absolute;
  top: -85px;
  left: -85px;
  width: 171px;
  height: 171px;
  border-radius: 50%;
  background-color: rgba(234, 77, 77, 0.5);
  animation: circle_motion 3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
}

@keyframes circle_motion {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(0.7);
  }
}

.daiba_page .bg .point {
  position: relative;
  width: 13px;
  height: 13px;
  margin: -6px 0 0 -6px;
  z-index: 100;
  animation: point_motion 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s infinite;
}

@keyframes point_motion {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.daiba_page .bg .point1 {
  z-index: 200;
}

.daiba_page .bg .point1 .line {
  position: relative;
  margin: -98px 0 0 -30px;
}

.daiba_page .bg .point1 .line img {
  width: 31px;
  height: 83px;
}

.daiba_page .bg .point2 {
  z-index: 100;
}

.daiba_page .bg .point2 .line {
  position: relative;
  margin: -100px 0 0 0;
}

.daiba_page .bg .point2 .line img {
  width: 21px;
  height: 86px;
}

.daiba_page .bg .line .name {
  padding: 4px 27px 4px 12px;
  font-size: 16px;
  letter-spacing: 0.93px;
  text-align: center;
  background-color: #ffffff;
  background-image: url('../img/button_arrow2.svg');
  background-repeat: no-repeat;
  background-position: 91% center;
  background-size: 8px 14px;
}
.ja.sp.daiba_page .bg .line .name {
  padding: 4px 27px 4px 12px;
}

.daiba_page .bg .point1 .line .name {
  margin: -90px 0 0 -40px;
}
.daiba_page .bg .point2 .line .name {
  margin: -88px 0 0 -28px;
}
.en.daiba_page .bg .point1 .line .name {
  margin: -90px 0 0 -110px;
}
.th.daiba_page .bg .point1 .line .name {
  margin: -90px 0 0 -110px;
}

.th.daiba_page .bg .point2 .line .name {
  margin: -88px 0 0 -45px;
}
