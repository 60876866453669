html, body, h1, h2, h3, h4, ul, ol, dl, li, dt, dd, p, div, span, img, a, table, tr, th, td {
	margin: 0;
	padding: 0;
	border: 0;
	font-weight: normal;
	font-size: 100%;
	vertical-align: baseline;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

article, header, footer, aside, figure, figcaption, nav, section {
	display: block;
}

body {
	line-height: 1;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

h1 {
	margin: 0;
}

ol, ul {
	list-style: none;
	list-style-type: none;
}

li {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

table tr th,
table tr td {
	padding: 10px 0;
	text-align: left;
	vertical-align: middle;
	border: 1px solid #efefef;
	border-collapse: collapse;
}

img,
video {
	vertical-align: bottom;
}

*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}
